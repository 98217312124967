import React from "react";
import { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import Table from "../Common/Table/Table";
import { deleteUser } from "../../utils/userUtils/userUtils";

const SavedUsersTable = ({ datas, setEditData, setOpen, setDatasFn }) => {
  const headings = useMemo(
    () => [
      "Name",
      "Number",
      "Email",
      "Edit",
      "Delete"
    ],
    []
  );
  const delData = (id) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteUser(id)
      .then((res) => {
        setDatasFn();
      })
      .catch((error) => console.error(error));
  };
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 ">{data?.name}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.phone_number}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.email_id}</div>
          </td>
          <td
            className={` px-6 py-4 whitespace-nowrap `}
          >
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              <CiEdit color="green" />
            </div>
          </td>
          <td
            className={`px-6 py-4 whitespace-nowrap `}
          >
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delData(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default SavedUsersTable;

import { axiosInstance } from '../../api/config';

export const handleLogin = async (initialState, setErr) => {
  try {
    const response = await axiosInstance
      .post(
        `/bdregister/login`,
        {
          bd_id: initialState.bd_id.trim(),
          password: initialState.password,
        },
        {
          withCredentials: true,
          headers: { 'Content-Type': 'application/json' }
        }
      );
    return response;
  } catch (error) {
    if (error.response?.status === 401 || error?.response?.data?.error === "Login failed. Please check your credentials and try again.") {
      setErr({ global: `Credentials not matching.` });
    } else {
      setErr({ global: "Something went wrong. Please try again later." });
    }
    throw error;
  }
};


export const verifyEmail = async (email_id, setErr) => {
  try {
    const response = await axiosInstance.post(`/bdregister/forgotpasswordemailverify`, { email_id }, { headers: { "Content-Type": "application/json" } });
    return response;
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.data?.error?.error === "Bd is Not Found By This Email!"
    ) {
      setErr({ global: "This email address is not registered." });
    } else {
      setErr({ global: "Failed to send OTP. Please try again." });
    }
    throw error;
  }
}

export const verifyOtp = async (email_id, otpforgotPassword, setErr) => {
  try {
    const response = await axiosInstance.post(`/bdregister/forgotpasswordotpverify`, { email_id, otpforgotPassword }, { headers: { "Content-Type": "application/json" } });
    return response;
  } catch (error) {
    if (error?.response?.status === 400) {
      if (error?.response?.data?.error?.message === "Invalid OTP") {
        setErr({ global: "The OTP is incorrect. Please check and try again." });
      } else if (error?.response?.data?.error?.message === "OTP has expired") {
        setErr({ global: "OTP expired. Please request a new one." });
      }
    } else {
      setErr({ global: "Failed to verify OTP. Please try again." });
    }
    throw error;
  }
}

export const submitNewPassword = async (token, password, setErr) => {
  try {
    const response = await axiosInstance.post(`/bdregister/forgotpasswordotpset`, { token, password }, { headers: { "Content-Type": "application/json" } });
    return response;
  } catch (error) {
    if (error?.response?.status === 400) {
      if (error?.response?.data?.error?.message === "Reset token required.") {
        setErr("Session expired. Please request a new one.");
      } else if (error?.response?.data?.error?.message === "Password is required.") {
        setErr("Password is required.");
      }
    }
    if (error?.response?.status === 403) {
      setErr("Session expired. Please request a new one.");
    } else {
      setErr("Failed to change password. Please try again.");
    }
    throw error;
  }
}
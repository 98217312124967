import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { MyUsers, Login, SavedUsers } from './pages';
import { AuthProtected, UserProtected } from './components/ProtectedRoutes/UserProtected';

function App() {
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<MyUsers />}/>
        <Route path="/saved" element={<SavedUsers />}/>
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;

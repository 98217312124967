import React from "react";
import SavedUsersMainSection from "../../components/SavedUserComponents/SavedUsersMainSection";

const SavedUsers = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <SavedUsersMainSection/>
    </div>
  );
};

export default SavedUsers;

import { axiosAuthorized } from "../../api/config"

export const getUsers = async (userId) => {
  try {
    const response = await axiosAuthorized.get(`/getusercompletedregisterdata/${userId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getSavedUsers = async (userId) => {
  try {
    const response = await axiosAuthorized.get(`/getusersaveddata/${userId}`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addUser = async (initialState, user_saved, id = null) => {
  try {
    const data = {
      ...initialState,
      phone_number: Number(initialState.phone_number),
    };
    const requestData = user_saved !== null ? { ...data, user_saved } : { ...data };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/salesbduser/update_salesbduser/${id}`
      : `/salesbduser/add_bdregister`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteUser = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/salesbduser/delete_salesbduser/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}